import { AuthorizationCodeMessages, AuthorizationCodeOptions } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export const getAuthorizationCodeMessages = (translate: TFunction, { email, phone }: AuthorizationCodeOptions): AuthorizationCodeMessages => ({
  title: translate('authorizationCodePage.title'),
  authorizationCode: {
    label: translate('authorizationCodePage.authorizationCode.label'),
    placeholder: translate('authorizationCodePage.authorizationCode.placeholder'),
  },
  authorizationCodeMessages: {
    error: translate('authorizationCodePage.authorizationCodeMessages.error'),
    success: translate('authorizationCodePage.authorizationCodeMessages.success'),
  },
  authorizationInstructions: {
    emailAndPhoneNumber: translate('authorizationCodePage.authorizationInstructions.emailAndPhoneNumber', { phone, email: email || '' }),
    phoneNumber: translate('authorizationCodePage.authorizationInstructions.phoneNumber', { phone }),
    email: translate('authorizationCodePage.authorizationInstructions.email', { email: email || '' }),
  },
  resendCodeMessage: translate('authorizationCodePage.resendCodeMessage'),
  resendCodeButton: translate('authorizationCodePage.resendCodeButton'),
  nextButtonLabel: translate('nextButtonLabel'),
  previousButtonLabel: translate('previousButtonLabel'),
  dialog: {
    infoText: {
      unexpectedError: translate('authorizationCodePage.dialog.unexpectedError'),
    },
    closeButtonText: translate('authorizationCodePage.dialog.closeButtonText'),
  }
})
