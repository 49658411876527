import { AcceptAuthorizationCodeParams } from '@/domain/use-cases/authorization-code/accept-authorization-code'
import { AuthorizationCodeResponse, SendAuthorizationCodeParams } from '@/domain/use-cases/authorization-code/authorization-code'
import { makeRemoteAcceptAuthorizationCode, makeRemoteSendAuthorizationCode } from '@/main/factories/remote/authorization-code'

export type AuthorizationCodePageApi = {
  acceptAuthorizationCode: (data: AcceptAuthorizationCodeParams) => Promise<boolean> | void
  sendAuthorizationCode: (sendAuthorizationCodeParams: SendAuthorizationCodeParams) => Promise<AuthorizationCodeResponse>
}

export const makeAuthorizationCodePageApi = ({ isHostedUIEnabled }): AuthorizationCodePageApi => {
  const remoteAcceptAuthorizationCode = makeRemoteAcceptAuthorizationCode()
  const remoteSendAuthorizationCode = makeRemoteSendAuthorizationCode({ shouldRequestWithApiKey: isHostedUIEnabled })
  const acceptAuthorizationCode = (params: AcceptAuthorizationCodeParams) => remoteAcceptAuthorizationCode.accept(params)
  const sendAuthorizationCode = (params: SendAuthorizationCodeParams) => remoteSendAuthorizationCode.sendAuthorizationCode(params)

  return { acceptAuthorizationCode, sendAuthorizationCode }
}
